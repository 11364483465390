import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Section from "../../../containers/Section";
import Header, { HeaderVariant } from "../../Header";
import EcommerceListItem from "./EcommerceListItem";

interface EcommerceProps {
  title?: string;
  features?: any;
  quoteLogo?: any;
  quoteDescription?: string;
  quotePerson?: string;
  quotePersonTitle?: string;
  quotePersonImage?: any;
}

const EcommerceSection: React.FC<EcommerceProps> = props => {
  const {
    title,
    features,
    quoteLogo,
    quoteDescription,
    quotePerson,
    quotePersonTitle,
    quotePersonImage
  } = props;
  const pageQuery = useStaticQuery(graphql`
    query {
      ecommerce: file(relativePath: { eq: "Agencies/ecommerce.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FIXED)
        }
      }
    }
  `);

  return (
    <div className="bg-gradient-to-r from-gray-6 to-gray-5 rounded-tl-large rounded-br-large">
      <Section>
        <div className="flex lg:flex-row flex-col-reverse py-20 lg:py-36 items-center">
          <div className="w-full lg:w-1/2">
            <div className="mt-14 md:mt-20 lg:mt-0 lg:mr-24">
              <BackgroundImage
                fixed={pageQuery.ecommerce.childImageSharp.gatsbyImageData}
                style={{
                  width: "100%",
                  height: "50%",
                  borderTopRightRadius: "6px",
                  borderBottomLeftRadius: "6px",
                  borderTopLeftRadius: "120px",
                  borderBottomRightRadius: "120px",
                  overflow: "hidden"
                }}
              >
                <div
                  style={{
                    background: `linear-gradient(to right, rgba(66,165,245,1), rgba(66,165,245,0.8))`,
                    width: "100%",
                    height: "100%",
                    alignItems: "flex-start",
                    padding: "52px",
                    overflow: "hidden"
                  }}
                >
                  <GatsbyImage
                    alt=""
                    image={quoteLogo}
                    style={{ width: "162px", height: "39px" }}
                  />
                  <p className="text-2xl font-semibold text-white pt-14">
                    {quoteDescription}
                  </p>
                  <div className="flex justify-start items-center pt-14">
                    <GatsbyImage
                      alt="Price Glomski pmg."
                      image={quotePersonImage}
                      style={{ width: "48px", height: "48px" }}
                    />
                    <p className="text-base font-medium text-white px-4">
                      <span className="font-semibold text-lg">
                        {quotePerson}
                      </span>
                      <p>{quotePersonTitle}</p>
                    </p>
                  </div>
                </div>
              </BackgroundImage>
            </div>
          </div>

          {/* heading and list items */}
          <div className="w-full lg:w-1/2">
            <Header
              className="text-gray-3-dark text-center md:text-left"
              variant={HeaderVariant.h2}
            >
              {title}
            </Header>
            {features &&
              features?.map(list => {
                return <EcommerceListItem text={list?.text} />;
              })}
          </div>
        </div>
      </Section>
    </div>
  );
};

export default EcommerceSection;
