import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const SideBox: React.FC<{}> = props => {
  return (
    <div className="w-96 md:w-full lg:w-80 text-center p-12 rounded-md border border-gray-4-light">
      <p className="text-2xl text-gray-2-light font-semibold px-4 mb-12">
        {props.sideBoxTitle}
      </p>
      <div className="pt-12 border-t border-gray-4-light flex flex-col md:flex-row lg:flex-col justify-between items-center space-y-10 md:space-y-0 lg:space-y-10">
        {props?.sideBoxImages?.map(image => {
          return <GatsbyImage image={image?.gatsbyImageData} alt="Snowflake" />;
        })}
      </div>
    </div>
  );
};

export default SideBox;
