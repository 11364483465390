import React from "react";
import classNames from "classnames";
import { IconType } from "../../../../components/Icon";
import Bullet, {
  BulletSize,
  BulletVariant
} from "../../../../components/Bullet";

interface CardProps {
  iconType?: IconType;
  iconSvg?: any;
  title: string;
  description: string;
  className?: string;
}
const Card: React.FC<CardProps> = props => {
  const { iconType, iconSvg, title, description, className } = props;
  return (
    <span className={classNames("", className)}>
      {iconSvg ? (
        <span className="rounded-full inline-block bg-blue-3-light p-4">
          <img src={iconSvg} alt="" />
        </span>
      ) : iconType ? (
        <Bullet
          iconType={iconType}
          size={BulletSize.default}
          variant={BulletVariant.blue}
        />
      ) : (
        ""
      )}
      <p className="text-gray-3-dark font-semibold text-2xl mt-5 mb-3">
        {title}
      </p>
      <p className="text-gray-1 text-lg font-medium">{description}</p>
    </span>
  );
};

export default Card;
