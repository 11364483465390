import React from "react";
import classNames from "classnames";
import ListCardContent from "../ListCardContent";

interface ListCardProps {
  title: string;
  bullets: string[];
  className?: string;
}

const ListCard: React.FC<ListCardProps> = props => {
  const { title, bullets, className } = props;
  return (
    <div
      className={classNames(
        "bg-gray-6-light flex flex-col rounded overflow-hidden p-10",
        className
      )}
    >
      <p className="text-3xl font-semibold text-gray-3-dark text-center">
        {title}
      </p>

      <div className="mt-10">
        {bullets?.map((bullet, index) => (
          <ListCardContent
            key={index}
            text={bullet?.text}
            listItem={index}
            bulletCount={bullets.length}
          />
        ))}
      </div>
    </div>
  );
};

export default ListCard;
