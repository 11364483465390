import React from "react";

interface VideoProps {
  className?: string;
}

const Video: React.FC<VideoProps> = props => {
  const { className } = props;
  return (
    <div className={className}>
      <iframe
        src="https://player.vimeo.com/video/347067529?autoplay=1?muted=1"
        allow="autoplay; fullscreen; picture-in-picture"
        width="100%"
        height="100%"
        style={{ borderRadius: "12px" }}
      ></iframe>
    </div>
  );
};

export default Video;
