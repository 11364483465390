import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Header, { HeaderVariant } from "../../../components/Header";
import Paragraph from "../../../components/Paragraph";
import Section from "../../../containers/Section";
import CardSection from "./CardSection";
import SideBox from "./SideBox";

interface EmpowermentProps {
  title?: string;
  description?: string;
  image?: any;
  features?: any;
  sideBoxTitle?: string;
  sideBoxImages?: any;
}
const EmpowermentSection: React.FC<EmpowermentProps> = props => {
  const { title, description, image, features, sideBoxImages, sideBoxTitle } =
    props;

  return (
    <Section>
      {/* Heading, Desc and Image */}
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-24 py-20 lg:py-36">
        <div className="text-center md:text-left">
          <Header className="text-gray-3-dark" variant={HeaderVariant.h2}>
            {title}
          </Header>
          <Paragraph className="md:text-2xl md:font-semibold text-gray-3-dark">
            {description}
          </Paragraph>
        </div>
        <div>
          <GatsbyImage
            alt=""
            image={image}
            className="rounded-br-large rounded-md mt-16 md:mt-20 lg:mt-0"
          />
        </div>
      </div>

      {/* Cards and Side Box */}
      <div className="flex lg:flex-row lg:justify-between lg:content-center md:items-stretch items-center flex-col pb-20 lg:pb-36">
        <CardSection features={features} />
        <span className="lg:ml-44 lg:mt-0 md:mt-20 mt-16">
          <SideBox sideBoxTitle={sideBoxTitle} sideBoxImages={sideBoxImages} />
        </span>
      </div>
    </Section>
  );
};

export default EmpowermentSection;
