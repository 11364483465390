import React from "react";
import { IconType } from "../../../../components/Icon";
import Card from "../Card";

interface CardProps {
  features: any;
}

const CardSection: React.FC<CardProps> = props => {
  const { features } = props;
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-y-16 lg:gap-y-20 md:gap-x-6 lg:gap-x-24 text-center md:text-left">
      {features &&
        features?.map((feature, index) => {
          return (
            <Card
              iconSvg={feature?.icon?.url}
              title={feature?.title}
              description={feature?.description?.description}
            />
          );
        })}
    </div>
  );
};

export default CardSection;
