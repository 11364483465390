import classNames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import Badge from "../../../components/Badge";
import Button, { ButtonSize, ButtonVariant } from "../../../components/Button";
import Header, { HeaderVariant } from "../../../components/Header";
import Icon, { IconType } from "../../../components/Icon";
import Paragraph from "../../../components/Paragraph";
import { CTA } from "../../../constants/text";
import { Intercom } from "../../../constants/destinations";
import Section from "../../../containers/Section";
import { useSegment, trackView } from "../../../utils/analytics/index";
import Brands, { BrandObject } from "../../Brands";
import BraveOne from "../../Brands/BrandSvgs/braveone.svg";
// import brand svgs
import Cartograph from "../../Brands/BrandSvgs/cartograph.svg";
import Ignite from "../../Brands/BrandSvgs/ignite.svg";
import Incrementum from "../../Brands/BrandSvgs/incrementum.svg";
import Pmg from "../../Brands/BrandSvgs/pmg.svg";
import Podean from "../../Brands/BrandSvgs/podean.svg";
import Power from "../../Brands/BrandSvgs/power.svg";
import Video from "./Video";
import {
  Name,
  Type,
  Location,
  Category
} from "../../../utils/analytics/constants";
import { GatsbyImage } from "gatsby-plugin-image";
import GradientTitle from "../../GradientTitle";

interface HeroSectionProps {
  title?: string;
  gradientTitlePosition?: number;
  subtitle?: string;
  description?: string;
  heroCta?: any;
  image?: any;
  imageSvg?: any;
}

const HeroSection: React.FC<HeroSectionProps> = props => {
  const {
    title,
    gradientTitlePosition,
    subtitle,
    description,
    heroCta,
    image,
    imageSvg
  } = props;
  // state to control video loading
  const [load, setLoad] = React.useState<Boolean>(false);

  const { segment } = useSegment();

  // array of brand svgs
  const brandsList: Array<BrandObject> = [
    { src: <Cartograph />, filter: true },
    { src: <Podean />, filter: true },
    { src: <Pmg />, filter: true },
    { src: <Incrementum />, filter: true },
    { src: <Ignite />, filter: true },
    { src: <BraveOne />, filter: true },
    { src: <Power />, filter: true }
  ];

  return (
    <div className="bg-gradient-to-b from-white via-gray-5 to-gray-6 rounded-br-large">
      <Section>
        <div className="relative py-16">
          <div className="flex justify-center md:justify-start">
            <Badge
              iconType={IconType.union}
              title={subtitle}
              className="mb-12"
            />
          </div>

          <div className="flex flex-col lg:flex-row">
            <span className="text-center md:text-left content-center md:justify-start w-full lg:w-1/2 ">
              <GradientTitle
                title={title}
                gradientPosition={gradientTitlePosition}
                variant={HeaderVariant.h1}
                color="purple"
                lineBreak={false}
                className="md:text-5xl"
              />
              <Paragraph className="text-gray-3-dark">{description}</Paragraph>
              <Button
                variant={ButtonVariant.primary}
                size={ButtonSize.large}
                text={heroCta?.text}
                className="mt-12 md:mt-10 md:mx-0 md:w-auto w-full mx-auto block"
                onClick={() => {
                  trackView(Name.Cta, {
                    category: Category.ButtonClick,
                    type: Type.Button,
                    text: heroCta?.text,
                    page_url: window?.location.href,
                    destination: heroCta?.route,
                    location: Location.Herobanner
                  });
                  window.location.href = heroCta?.route;
                }}
              />
            </span>
            <span className="relative w-full lg:w-1/2 lg:mt-0 lg:ml-20 md:mt-20 mt-16">
              <div className="relative">
                <GatsbyImage
                  alt=""
                  image={image}
                  className={classNames("rounded-xl top-0 left-0", {
                    invisible: load
                  })}
                />
                {!load && (
                  <div className=" bg-white absolute -bottom-6 md:right-6 right-1/2 -mr-24 md:-mr-0 p-4 rounded-lg shadow-xl">
                    <img src={imageSvg} alt="" />
                  </div>
                )}
                <div onClick={() => setLoad(true)}>
                  <Icon
                    iconType={IconType.play}
                    className="absolute top-1/2 left-1/2 -mt-9 -ml-9 cursor-pointer transition duration-500 ease-in-out hover:-translate-y-1 hover:scale-110"
                  />
                </div>
                {load && (
                  <Video className="absolute top-0 left-0 w-full h-full" />
                )}
              </div>
            </span>
          </div>
          <div className="mt-20 md:mt-24 lg:mt-20">
            <Brands brandsList={brandsList} />
          </div>
        </div>
      </Section>
    </div>
  );
};

export default HeroSection;
