import React from "react";
import classNames from "classnames";

import Bullet, {
  BulletSize,
  BulletVariant
} from "../../../../components/Bullet";
import { IconType } from "../../../../components/Icon";
import Paragraph from "../../../../components/Paragraph";

interface ListCardContentProps {
  text: string;
  listItem: number;
  bulletCount: number;
}

const ListCardContent: React.FC<ListCardContentProps> = props => {
  const { text, listItem, bulletCount } = props;
  return (
    <div className="flex">
      <div className="flex-none relative overflow-hidden w-20">
        <div
          className={classNames(
            "absolute left-1/2 translate-x-1/2 h-full w-0.5 bg-gray-4-light z-10",
            {
              "top-1/2": listItem === 0,
              "bottom-1/2": listItem === bulletCount - 1
            }
          )}
        ></div>
        <div className="absolute z-20 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
          <Bullet
            iconType={IconType.checkbox}
            variant={BulletVariant.white}
            size={BulletSize.small}
          />
        </div>
      </div>
      <Paragraph className="text-gray-1 text-xl font-medium py-5 text-left">
        {text}
      </Paragraph>
    </div>
  );
};

export default ListCardContent;
