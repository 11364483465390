import { useStaticQuery, graphql } from "gatsby";
import { PageProps } from "gatsby";
import React from "react";
import DayoneSection from "../components/AgencyPage/DayoneSection";
import EcommerceSection from "../components/AgencyPage/EcommerceSection";
import EmpowermentSection from "../components/AgencyPage/EmpowermentSection";
import HeroSection from "../components/AgencyPage/HeroSection";
import BottomCTA, { BottomCTAVariant } from "../components/BottomCta";
import NavigationBarSpacer from "../components/NavigationBarSpacer";
import SEO from "../components/Seo";
import { CTA } from "../constants/text";
import { Intercom } from "../constants/destinations";
import Layout from "../containers/Layout";
import { useSegment, trackView } from "../utils/analytics";
import { Name, Type, Location, Category, CTA_ID } from "../utils/analytics/constants";
import { nav } from "../utils/navigation";

interface AgenciesProps {
  data: any;
}

const AgenciesPage: React.FC<AgenciesProps> = props => {
  const { data } = props;
  const { segment } = useSegment();

  const pageData = data?.allContentfulAgenciesPage?.nodes[0];

  const dayOne = [
    {
      title: pageData?.onboardingCard,
      cards: pageData?.onboardingCardList
    },
    {
      title: pageData?.ongoingSupportCard,
      cards: pageData?.ongoingSupportCardList
    },
    {
      title: pageData?.accelerationCard,
      cards: pageData?.accelerationCardList
    }
  ];

  return (
    <Layout primaryButtonId={CTA_ID.getStartedNav.id}>
      <SEO
        title={pageData?.seoSettings?.metadataTitle}
        description={pageData?.seoSettings?.metadataDescription}
        image={pageData?.seoSettings?.openGraphImage?.url}
        robots={pageData?.seoSettings?.robots}
      />
      <NavigationBarSpacer />
      <HeroSection
        title={pageData?.agenciesGradientMainTitle}
        gradientTitlePosition={pageData?.agenciesGradientTitlePosition}
        subtitle={pageData?.topTabTitle}
        description={pageData?.agenciesMainDescription?.agenciesMainDescription}
        heroCta={pageData?.heroCta}
        image={pageData?.heroImage?.gatsbyImageData}
        imageSvg={pageData?.heroImageSvg?.url}
      />
      <EmpowermentSection
        title={pageData?.empowermentTitle}
        description={pageData?.empowermentDescription}
        image={pageData?.empowermentImage?.gatsbyImageData}
        features={pageData?.empowermentPoints}
        sideBoxTitle={pageData?.integrationsTitle}
        sideBoxImages={pageData?.integrationsImages}
      />
      <EcommerceSection
        title={pageData?.agenciesNeedsTitle}
        features={pageData?.agenciesBulletPoints}
        quoteLogo={pageData?.blueQuoteImage?.gatsbyImageData}
        quoteDescription={pageData?.blueQuoteDescription?.blueQuoteDescription}
        quotePerson={pageData?.quotePersonName}
        quotePersonTitle={pageData?.quotePersonTitle}
        quotePersonImage={pageData?.quotePersonImage?.gatsbyImageData}
      />
      <DayoneSection
        title={pageData?.onboardingSectionTitle}
        dayOneCards={dayOne}
      />
      <BottomCTA
        variant={BottomCTAVariant.secondary}
        title={pageData?.bottomCta?.title}
        secondaryButtonText={pageData?.bottomCta?.secondaryButtonText}
        primaryButtonClassName={pageData?.bottomCta?.primaryButtonClassName}
        secondaryButtonClassName={pageData?.bottomCta?.secondaryButtonClassName}
        secondaryButtonId={CTA_ID.demoBottom.id}
        secondaryButtonOnClick={() => {
          trackView(Name.Cta, {
            category: Category.ButtonClick,
            type: Type.Button,
            text: pageData?.bottomCta?.secondaryButtonText,
            page_url: window?.location.href,
            destination: pageData?.bottomCta?.secondaryButtonRoute,
            location: Location.BottomCta
          });
          nav(pageData?.bottomCta?.secondaryButtonRoute);
        }}
      />
    </Layout>
  );
};

export default AgenciesPage;

export const data = graphql`
  query ($language: String) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allContentfulAgenciesPage(filter: { node_locale: { eq: $language } }) {
      nodes {
        seoSettings {
          metadataTitle
          metadataDescription
          openGraphImage {
            gatsbyImageData
            url
          }
          robots
        }
        topTabTitle
        agenciesGradientMainTitle
        agenciesGradientTitlePosition
        agenciesMainDescription {
          agenciesMainDescription
        }
        heroCta {
          text
          route
        }
        heroImage {
          gatsbyImageData
          url
        }
        heroImageSvg {
          gatsbyImageData
          url
        }
        empowermentTitle
        empowermentDescription
        empowermentImage {
          gatsbyImageData
          url
        }
        empowermentPoints {
          title
          description {
            description
          }
          icon {
            gatsbyImageData
            url
          }
        }
        integrationsTitle
        integrationsImages {
          gatsbyImageData(width: 160, layout: FIXED)
          url
        }
        agenciesNeedsTitle
        agenciesBulletPoints {
          text
          svg {
            url
          }
        }
        blueQuoteImage {
          gatsbyImageData
          url
        }
        blueQuoteDescription {
          blueQuoteDescription
        }
        quotePersonName
        quotePersonTitle
        quotePersonImage {
          gatsbyImageData
          url
        }
        onboardingSectionTitle
        onboardingCard
        onboardingCardList {
          text
          svg {
            url
          }
        }
        ongoingSupportCard
        ongoingSupportCardList {
          text
          svg {
            url
          }
        }
        accelerationCard
        accelerationCardList {
          text
          svg {
            url
          }
        }
        bottomCta {
          title
          subtitle
          primaryButtonId
          primaryButtonText
          secondaryButtonId
          secondaryButtonText
          primaryButtonRoute
          secondaryButtonRoute
          primaryButtonClassName
          secondaryButtonClassName
        }
      }
    }
  }
`;
