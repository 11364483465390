import React from "react";
import Icon, { IconType } from "../../../../components/Icon";
import Paragraph from "../../../../components/Paragraph";

interface EcommerceListItemProps {
  text: string;
}

const EcommerceListItem: React.FC<EcommerceListItemProps> = props => {
  const { text } = props;
  return (
    <div className="flex flex-row items-center max-w-max space-y-5">
      <Icon iconType={IconType.ellipse} />
      <Paragraph className="text-xl font-medium text-gray-1 ml-6">
        {text}
      </Paragraph>
    </div>
  );
};

export default EcommerceListItem;
