import React from "react";
import Section from "../../../containers/Section";
import ListCard from "./ListCard";

interface DayOneProps {
  title?: string;
  dayOneCards?: any;
}

const DayoneSection: React.FC<DayOneProps> = props => {
  const { title, dayOneCards } = props;

  return (
    <Section className="lg:px-0">
      <div className="flex flex-col py-20 lg:py-36 justify-center">
        <div className="text-4xl font-semibold text-gray-3-dark text-center mb-14 md:mb-20 lg:mb-24 lg:px-40">
          {title}
        </div>

        <div className="flex flex-col lg:flex-row">
          {dayOneCards?.map((item, index) => {
            return (
              <ListCard
                title={item.title}
                bullets={item.cards}
                className="my-6 lg:my-0 lg:mx-5 w-full lg:flex-1"
              />
            );
          })}
        </div>
      </div>
    </Section>
  );
};

export default DayoneSection;
